<template>
  <div class="docter_detail">
    <p class="tip">重要：急重症不适合网上咨询，请立即前往当地医院就诊！</p>
    <div class="header">
      <img class="icon_tx" :src="docDetail.image" v-if="docType == 2" alt="">
      <img class="icon_tx" :src="devDoctorDetail.doctorHeadImg" v-if="docType == 1" alt="">
      <div class="right" v-if="docType == 2">
        <div class="follow" v-if="!is_follow" @click="sureFollow(true)">
          <img src="../assets/img/follow.png" alt="">
          <p>关注</p>
        </div>
        <div class="followed" v-else @click="cancelFollow(false)">
          <img src="../assets/img/followed.png" alt="">
        </div>
        <div class="docter_info">
          <span>{{docDetail.name}}</span>
          <span>{{docDetail.clinic_name}}</span>
          <span>{{docDetail.title}}</span>
        </div>
        <p class="hospital">{{docDetail.hospital}}</p>
        <div class="describe">
          擅长：{{docDetail.good_at}}
        </div>
        <div class="bottom">
          <div class="item">
            <p>
              <span>{{docDetail.reply_num}}</span>
              <span>人</span>
            </p>
            <p>服务人数</p>
          </div>
          <div class="item item2">
            <p>
              <span>{{docDetail.good_rate}}</span>
              <span>%</span>
            </p>
            <p>好评率</p>
          </div>
          <div class="item">
            <p>
              <span>{{docDetail.solution_score}}</span>
              <span>分</span>
            </p>
            <p>同行认可</p>
          </div>
        </div>
      </div>
      <div class="right" v-if="docType == 1">
        <div class="follow" v-if="!is_follow" @click="sureFollow(true)">
          <img src="../assets/img/follow.png" alt="">
          <p>关注</p>
        </div>
        <div class="followed" v-else @click="cancelFollow(false)">
          <img src="../assets/img/followed.png" alt="">
        </div>
        <div class="docter_info">
          <span>{{devDoctorDetail.doctorName}}</span>
          <span>{{devDoctorDetail.doctorClinic}}</span>
          <span>{{devDoctorDetail.doctorProfessionalTitle}}</span>
        </div>
        <p class="hospital">{{devDoctorDetail.doctorHospitalName}}</p>
        <div class="describe">
          擅长：{{devDoctorDetail.adeptTerritory}}
        </div>
      </div>
    </div>
    <div class="choose_type">
      <div class="item" :class="[type == 1 ? 'check_type' : '']" @click="handleChange1">
        <div class="Notopen" v-if="(!is_graphic) && docType == 2">暂未开通</div>
        <div class="Notopen" v-if="(!is_graphic) && docType == 1">暂未开通</div>
        <div class="top">
          <img src="../assets/img/img_ask.png" alt="">
          <div class="top_right">
            <p>图文咨询</p>
            <p>
              <span>￥</span>
              <span v-if="docType == 2">{{docDetail.price}}</span>
              <span v-else>{{devPrice}}</span>
              <span>/次</span>
            </p>
          </div>
        </div>
        <p class="bottom">通过文字，语音，图片形式和医生沟通</p>
      </div>
      <!-- <p></p> -->
      <div class="item" @click="handleChange2" :class="[(type == 2 && isClick == false) ? 'check_type' : '']">
        <div class="Notopen" v-if="isClick">暂未开通</div>
        <div class="Notopen" v-if="(!is_tel) && docType == 2">暂未开通</div>
        <div class="top">
          <img src="../assets/img/phone.png" alt="">
          <div class="top_right">
            <p>电话咨询</p>
            <p>
              <span>￥</span>
              <span v-if="docType == 2">{{tel_price}}</span>
              <span v-else>{{devTelPrice}}</span>
              <span>/次</span>
            </p>
          </div>
        </div>
        <p class="bottom">以电话的形式和医生沟通，时间和时长可自主选择</p>
      </div>
    </div>
    <p class="bottom_tip">*医生未回复问题、未接通电话可退款</p>
    <div class="btn" v-if="!hideBtn">
      <button @click="submit">提交</button>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      type: 1,
      docDetail: {},
      devDoctorDetail: {},
      tel_price: null,
      is_tel: true,
      is_graphic: true,
      is_follow: false,
      dataId: null,
      hideBtn: false,
      isClick: false,  // false不能点击咨询
      docType: 2,  // 2为春雨信息 1为药康夫
      devTelPrice: null, //药康夫电话问诊价格
      devPrice: null //药康夫图文问诊价格
    }
  },
  created() {
    if (this.$route.query.isClick) {
      this.isClick = true;
    } else {
      this.isClick = false;
    }

    if (this.$route.query.hideBtn) {
      this.hideBtn = this.$route.query.hideBtn;
    }
    if (this.$route.query.docType) {
      this.$store.state.docType = this.$route.query.docType;
      this.docType = this.$route.query.docType;
      if (this.$route.query.docType == 2) {
        this.dataId = this.$route.query.dataId;
        this.doctorDetail(this.$route.query.doctor_id);
      } else {
        this.devDocDetail(this.$route.query.doctor_id);

      }
    } else {
      this.doctorDetail(this.$route.query.doctor_id);
    }
    this.docIsFollow(this.$route.query.doctor_id);
    this.$nextTick(() => {
      // console.log(this.isClick, this.is_tel, this.is_graphic)
    })
  },
  methods: {
    // 药康夫医生价格
    devDocPrice(id) {
      let params = {
        doctorId: id
      }
      this.$http('get', '/server/doctorPriceConf/list', params, 1).then(res => {
        // console.log('价格', res);
        if (res.data.code == 200) {
          let arr = res.data.data;
          let devTelPrice = [];
          if (arr.length) {
            arr.forEach((item, index) => {
              if (item.consultType == 1) {
                if (item.consultPrice > 0) {
                  this.is_graphic = true;
                  this.devPrice = item.consultPrice,  //(item.consultPrice * (1 + this.$store.state.floatRate)).toFixed(2);
                    this.$store.state.devPrice = this.devPrice;
                } else {
                  this.is_graphic = false;
                }
              } else {
                if (item.consultPrice > 0) {
                  devTelPrice.push(item);
                }
              }
            })
            if (devTelPrice.length) {
              this.devTelPrice = (devTelPrice[0].consultPrice * (1 + this.$store.state.floatRate)).toFixed(2);
            } else {
              this.is_tel = false;
            }
          }
        }
      })
    },
    // 药康夫医生详情
    devDocDetail(id) {
      let params = {
        doctorId: id
      }
      this.$http('get', '/server/doctor/detail', params, 1).then(res => {
        // console.log(res);
        if (res.data.code == 200) {
          this.devDoctorDetail = res.data.data;
          if (this.devDoctorDetail.doctorAcceptStatus == 1) {
            this.devDocPrice(this.$route.query.doctor_id);
          } else {
            this.is_graphic = false;
            this.is_tel = false;
          }
        }
      })
    },
    // 查看医生是否关注
    docIsFollow(id) {
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let params = {
        memberId: userData.memberId,
        attentionType: this.docType,
        doctorId: id
      }
      this.$http('get', '/server/memberAttention/isAttention', params, 1).then(res => {
        if (res.data.code == 200) {
          this.is_follow = res.data.data;
        }
      })
    },
    // 确认关注
    sureFollow(follow) {
      if (this.docType == 2) {  //春雨医生关注
        this.saveDocInfo(follow);
      } else {  // 药康夫医生关注
        this.onFollow(this.devDoctorDetail.doctorId, 1, true);
      }
    },
    // 医生信息保存
    saveDocInfo(follow) {
      let params = {
        doctorId: this.docDetail.id,
        doctorName: this.docDetail.name,
        doctorImage: this.docDetail.image,
        doctorHospitalName: this.docDetail.hospital,
        doctorClinic: this.docDetail.clinic_name,
        doctorProfessionalTitle: this.docDetail.title,
        adeptTerritory: this.docDetail.good_at,
        buyPeopleCount: this.docDetail.reply_num
      }
      this.$http('post', '/server/onlineDoctorInfo/save', params, 1).then(res => {
        // console.log(res)
        if (res.data.code == 200) {
          // this.dataId = res.data.data;
          this.onFollow(res.data.data, 2, follow);
        }
      })
    },
    cancelFollow(follow) {
      if (this.docType == 2) { //春雨医生取消关注
        this.saveDocInfo(follow)
      } else { // 药康夫取消关注
        this.onFollow(this.devDoctorDetail.doctorId, 1, false);
      }

    },
    // 关注/取消关注
    onFollow(id, type, isFollow) {
      let userData = JSON.parse(localStorage.getItem('userData')) || ''
      let params = {
        memberId: userData.memberId,
        attentionType: type,  // 1是药康夫医生 2是春雨医生
        dataId: id, //主键id
        isAttention: isFollow  // true关注 false取消关注
      }
      this.$http('get', '/server/memberAttention/attention', params, 1).then(res => {
        if (res.data.code == 200) {
          if (isFollow) {
            Toast('关注成功');
          } else {
            Toast('取消关注成功')
          }
          this.is_follow = isFollow;
        } else {
          Toast(res.data.msg);
        }
      })
    },
    // 医生详情
    doctorDetail(id) {
      let params = {
        doctor_id: id
      }
      this.$http('post', '/cooperation/server/doctor/detail', params).then(res => {
        // console.log('详情', res);
        this.docDetail = res.data;
        if (this.docDetail.price > 0) {
          this.docDetail.price = ((this.docDetail.price / 100 * (1 + this.$store.state.floatRate))).toFixed(2);
          this.is_graphic = true;
        } else {
          this.is_graphic = false;
        }
        this.docDetail.tel_price = JSON.parse(this.docDetail.tel_price);
        let tel_price = this.docDetail.tel_price;
        // console.log(tel_price)
        let arr = [];
        for (let i in tel_price) {
          let item = tel_price[i];
          // console.log(item);
          if (item > 0) {
            // delete item
            arr.push(item);
          }
        }
        if (!arr.length) {
          this.is_tel = false;
        }
        this.tel_price = ((arr[0] / 100) * (1 + this.$store.state.floatRate)).toFixed(2);
      })
    },
    submit($event, type) {
      this.$store.state.docType = this.docType;
      if (this.docType == 1) {
        this.$store.state.docDetail = this.devDoctorDetail;
      } else {
        this.$store.state.docDetail = this.docDetail;
      }
      if (type) {
        this.type = type;
      }
      // console.log('this.type====>', this.is_graphic)
      if (this.type == 1) {
        if (!this.is_graphic) return;
        // if (this.$store.state.message) {
        this.$router.push({ path: '/choose_patients' })
        this.$store.state.visitsType = 1;
        // } else {
        //   this.$router.push({ path: '/choose_patients' })
        // }
      } else {
        if (this.isClick || (!this.is_tel)) return;
        if (this.docType == 2) {
          this.$router.push({ path: '/tel_visits', query: { doctor_id: this.docDetail.id, docType: this.docType } })
        } else {
          this.$router.push({ path: '/tel_visits', query: { doctor_id: this.devDoctorDetail.doctorId, docType: this.docType } })
        }





      }
    },
    handleChange1() {
      this.type = 1;
      // console.log('111')
    },
    handleChange2() {
      // console.log('2322')
      // if (this.doctorDetail.tel_online) {
      if (this.is_tel) {
        this.type = 2;

      }
      // }
    },
  }
}
</script>
<style lang="less" scoped>
.docter_detail {
  background: #f8f8f8;
  height: 100%;
  // padding-top: 0.22rem;
  .tip {
    color: #666666;
    font-size: 0.24rem;
    text-align: center;
    // margin: 0.22rem 0;
    line-height: 0.8rem;
    // margin-top: 0;
  }
  .header {
    background: #fff;
    border-radius: 4px;
    padding: 0.26rem 0.2rem;
    margin: 0 0.2rem;
    width: calc(100% - 0.8rem);
    display: flex;
    .icon_tx {
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 0.12rem;
      border-radius: 50%;
    }
    .right {
      width: calc(100% - 1.42rem);
      position: relative;
      .follow {
        text-align: center;
        margin-top: 0.5rem;
        position: absolute;
        right: 18px;
        top: -33px;
        img {
          width: 0.38rem;
          height: 0.38rem;
          // margin-bottom: 0.1rem;
        }
        p {
          color: #42cec8;
          font-size: 0.24rem;
        }
      }
      .followed {
        text-align: center;
        margin-top: 0.5rem;
        position: absolute;
        right: 18px;
        top: -20px;
        img {
          width: 0.38rem;
          height: 0.38rem;
          // margin-bottom: 0.1rem;
        }
        p {
          color: #42cec8;
          font-size: 0.24rem;
        }
      }
      .docter_info {
        margin-bottom: 0.14rem;
        span:nth-child(1) {
          font-size: 0.36rem;
          color: #222;
          font-weight: 700;
        }
        span:nth-child(2),
        span:nth-child(3) {
          font-size: 0.24rem;
          color: #666666;
          margin-left: 0.16rem;
        }
      }
      .hospital {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.28rem;
        color: #333333;
        font-weight: 400;
        margin-bottom: 0.1rem;
      }
      .describe {
        font-size: 0.24rem;
        color: #666666;
        width: 100%;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
      .bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 0.36rem;
        .item {
          text-align: center;
          p:nth-child(1) {
            font-size: 0.3rem;
            color: #333333;
            span:nth-child(2) {
              font-size: 0.2rem;
            }
          }
          p:nth-child(2) {
            font-size: 0.24rem;
            color: #666666;
          }
        }
        .item2 {
          p {
            color: #ffc025 !important;
          }
        }
      }
    }
  }
  .choose_type {
    margin: 0.28rem 0.2rem;
    width: calc(100% - 0.4rem);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    .item {
      // width: 36%;
      padding: 0.2rem;
      width: 2.9rem;
      background: #fff;
      border-radius: 4px;
      border: 2px solid #fff;
      position: relative;
      .Notopen {
        position: absolute;
        width: 100%;
        border-radius: 4px;
        height: 100%;
        top: 0;
        left: 0;
        background: #000;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 0.3rem;
        // z-index: 99
      }
      .top {
        display: flex;
        margin-bottom: 0.38rem;
        img {
          width: 0.74rem;
          height: 0.74rem;
          margin-right: 0.4rem;
        }
        .top_right {
          p:nth-child(1) {
            font-size: 0.3rem;
            color: #333333;
            font-weight: 400;
            line-height: 0.42rem;
          }
          p:nth-child(2) {
            color: #ffc025;
            span:nth-child(1),
            span:nth-child(3) {
              font-size: 0.24rem;
            }
            span:nth-child(2) {
              font-size: 0.36rem;
            }
          }
        }
      }
      .bottom {
        font-size: 0.24rem;
        color: #999999;
        line-height: 0.34rem;
        font-weight: 400;
        margin-bottom: 0.2rem;
      }
    }
    .check_type {
      border: 2px solid #42cec8 !important;
    }
  }
  .bottom_tip {
    font-size: 0.24rem;
    color: #999999;
    margin-top: 0.28rem;
    margin-left: 0.2rem;
  }
  .btn {
    margin: 2rem 0.4rem 0;
    width: calc(100% - 0.8rem);
    // margin-top: ;
    button {
      width: 100%;
      border-radius: 4px;
      background: #42cec8;
      border: 0;
      font-size: 0.32rem;
      color: #fff;
      height: 0.72rem;
    }
  }
}
</style>
